.footerComponent__container {
  width: 100%;
  background-color: #000;
  height: auto;
}

.footer__container {
  &__upperPart {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #fff;
  }

  &__contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;

    &__text {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    &__text span {
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    &__inputs {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: black;
    }
    &__inputs button {
      width: 100px;
      height: 35px;
      border: 1px solid #f20e0f;
      color: white;
      font-size: 10px;
      font-family: "Play-Bold";
      cursor: pointer;
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      background-color: #f20e0f;
    }

    &__input {
      width: 227px;
      height: 35px;
      background-color: #fff !important;
    }
    &__input::placeholder {
      font-size: 10px;
      text-align: left;
    }
  }
}

.inputs__input {
  width: 100px;
  height: 25px;
  display: flex;
}

@media (min-width: 700px) {
  .footer__container {
    width: 100%;
    height: 200px;
    background-image: url("../../../../zone-assets/images/footerImage.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .footer__container__contents {
    display: flex;
    flex-direction: row;
    margin-top: 70px;
  }

  .footer__container__upperPart {
    width: 95%;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .footer__container__contents__input {
    width: 300px;
    height: 58px;
    border-radius: 3px;
  }

  .footer__container__contents__inputs button {
    width: 161px;
    height: 58px;
    border-radius: 3px;
    font-size: 18px;
    font-family: $f-regular;
  }

  .footer__container__contents__text span {
    width: 195px;
    font-family: $f-regular;
    font-size: 15px;
    text-transform: none;
  }

  .footer__container__contents__input::placeholder {
    font-size: 15px;
    text-align: left;
  }
}
@media (min-width: 1000px) {
  .footer__container__contents__input {
    width: 600px;
  }
}