$base-color: #c6538c;
$border-dark: rgba($base-color, 0.88);

//colors
//c for color
$c-main-red: #c90200;
$c-darker-red: #8d2d32;
$c-darker-red: #8d2d32;
$c-white: #ffffff;
$c-text-black: #292929;
$c-text-gray: #707070;
$c-background-main: #f7f7f7;
$c-background-black: #000;
$c-background-dashboard: #f0f0f0;
$c-text-gray-dark-4d: #4d4f5c;
$c-text-nobel: #9f9f9f;
$c-border-gray: #dbdbdb;
$c-error-text: #ff0033;

// font families
// f for fonts
$f-regular: "Play-Regular";
$f-bold: "Play-Bold";

//dimensions
$d-mobilewidth: 550px;
$d-mobilewidth-small: 350px;
$d-header-mobilewidth: 730px;
$d-small: 750px;
$d-medium: 900px;
$d-large: 1050px;
$d-xlarge: 1250px;
$d-footer-mobile: 850px;
$d-iPad: 1200px;
