@import "../../fonts.scss";

$f-regular: "Play-Regular";
$f-bold: "Play-Bold";
$button-hover-color: #bd1f24;

.privacy-container {
  background-color: black;
  align-items: center;
}
.navbar-desktop {
  align-self: center;
  padding: 30px;
  margin-left: 50px;
}
.text {
  color: white;
  font-size: 15px;
  font-family: $f-regular;
  font-weight: normal;
}
.text-area {
  width: 1200px;
  padding-inline: 90px;
  margin-top: 30px;
  @media (max-width: 450px) {
    width: 430px;
    padding-inline: 30px;
    margin-top: 100px;
  }
}
.text-bold {
  font-weight:bold;
  font-size: 20px;
}
