.stepCard__container {
  &__eventName {
    display: flex;
    flex-direction: column;
    height: 50px;
    gap: 5px;

    &__span1 {
      font-size: 12px;
    }
  }
  &__nameSurname {
    display: flex;
    flex-direction: column;
    height: 50px;
    gap: 5px;

    &__span1 {
      font-size: 12px;
    }
  }
  &__guests {
    display: flex;
    flex-direction: column;
    height: 50px;
    gap: 5px;

    &__span1 {
      font-size: 12px;
    }
  }
  &__contact {
    display: flex;
    flex-direction: column;
    height: 50px;
    gap: 5px;

    &__span1 {
      font-size: 12px;
    }
  }
  &__table {
    display: flex;
    flex-direction: column;
    height: 50px;
    gap: 5px;

    &__span1 {
      font-size: 12px;
    }
  }
}
.step4__container__button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.stepTable__container {
  width: 50%;
  &__table {
    width: 100%;
    max-height: 220px;
    border: 1px solid #363636;
    border-radius: 5px;
    margin-top: 20px;
    background-color: #363636;
    overflow: auto;

    &__items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ffffff;
      padding: 8px;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.step1__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;

  &__firstInput {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  &__secondInput {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 0px;
    gap: 7px;

    &__input1 {
      width: 50%;
      // width: 144px;
    }
  }
  &__thirdInput {
    width: 100%;
    display: flex;
    gap: 7px;
    margin-top: 20px;
  }
  &__fourthInput {
    margin-top: 20px;
  }

  &__fifthInput {
    margin-top: 20px;
  }
  &__sixthInput {
    margin-top: 20px;
  }

  &__button {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
}

.step1__alls {
  width: 40%;
}

.steperComponent__steperDiv {
  width: 80%;
}

.step1__inputOne {
  width: 144px;
}
.step1__input1 {
  width: 100%;
}

.step1__container__input {
  width: 100% !important;
  height: 35px;
  background-color: #fff !important;
}

.step1__container__input::placeholder {
  font-size: 15px;
  text-align: left;
}

.step2__container {
  display: flex;
  &__img {
    width: 60%;
    img {
      width: 75%;
      height: 100%;
    }
  }

  &__right {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__itemsAndButton {
    display: flex;
    flex-direction: column;
  }
}

.step2__container__right__items__dropDowns {
  display: flex;
  gap: 5px;
}
.step2__icons__all {
  justify-content: flex-start;
  height: 300px;
  // margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.step2__icons {
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  gap: 5px;

  &__firstItem {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.step2__cube {
  width: 29px;
  height: 43px;
  border: 1px solid white;
}

.step2__lines {
  color: white;
  font-size: 30px;
}
.step2__lines2 {
  color: white;
  font-size: 30px;
}

.step2__rounder {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid white;
  color: white;
}

.step2__colors {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 12px;

  &__first {
    display: flex;
    gap: 20px;
    &__first {
      width: 15px;
      height: 15px;
      background-color: #e30613;
    }
  }

  &__second {
    display: flex;
    gap: 20px;

    &__second {
      width: 15px;
      height: 15px;
      background-color: #72246a;
    }
  }
  &__third {
    display: flex;
    gap: 20px;

    &__third {
      width: 15px;
      height: 15px;
      background-color: #4a9f99;
    }
  }
  &__fourth {
    display: flex;
    gap: 20px;

    &__fourth {
      width: 15px;
      height: 15px;
      background-color: #dda74a;
    }
  }
  &__fifth {
    display: flex;
    gap: 20px;

    &__fifth {
      width: 15px;
      height: 15px;
      background-color: #d9d7d8;
    }
  }
}

.step3__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__divs {
    // width: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__upperText {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    span {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }

  &__minimum {
    margin-top: 10px;
    width: 100%;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
  }

  &__dropDown {
    span {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 15px;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
  }
}

.step3__divs {
  width: 100%;
  display: flex;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__table {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.step1__container__items {
  width: 0%;
}

.ant-select-show-arrow {
  width: 100%;
}

.step4__container {
  width: 100%;

  &__alls {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__items {
    width: 100%;
    display: flex;

    &__left {
      width: 50%;

      &__eventName {
        display: flex;
        flex-direction: column;
      }
    }
    &__right {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;

      &__confirmCard {
        width: 207px;
        height: 47px;
        font-size: 15px;
        border: 2px solid #9f100f;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }

      &__table {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  // .step4__container__items__left__eventName {
  //   display: flex;
  //   flex-direction: column;
  // }

  &__button {
    width: 100%;
  }
}

.step6__container {
  width: 100%;

  &__items {
    width: 80%;
    display: flex;
    height: auto;
    justify-content: space-between;
    margin-top: 100px;

    &__left {
      width: 50%;

      &__eventName {
        display: flex;
        flex-direction: column;
      }
    }
    &__right {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;

      &__confirmCard {
        width: 207px;
        height: 47px;
        font-size: 15px;
        border: 2px solid #9f100f;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }

      &__table {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    .step6__container__items {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .step6__downContents {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 100%;
  }

  &__header {
    &__imgAndText {
      width: 82%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 200px;

      &__text {
        display: flex;
        flex-direction: column;

        &__span1 {
          width: 90%;
          font-size: 24px;
        }
        &__span2 {
          font-size: 14px;
        }
      }
    }
  }
}

.step6__container__button {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.step6__container__header__imgAndText__container {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 800px) {
  .step4__container__items {
    width: 100%;
  }
  .step1__alls {
    width: 90%;
  }

  .step2__container__img img {
    width: 100%;
  }
  .steperComponent__steperDiv {
    width: 80%;

    &__render {
      overflow-x: auto;
    }
  }
  .step1__container {
    width: 100%;
  }

  .step2__container__itemsAndButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .step2__container__items {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
  }

  .step2__icons__all {
    display: flex;
    justify-content: center;
    // margin-top: 40px;
    align-items: center;
    gap: 20px;
  }

  .step2__icons {
    margin: 20px 0 29px 0;
    font-size: 12px;
    gap: 20px;
  }

  .step2__colors {
    // width: 200px;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
  }
  .step2__container {
    flex-direction: column;
    &__right {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .step2__container__items__dropDowns {
    width: 100%;
  }

  .step4__container__items {
    flex-direction: column-reverse;
  }
  .step4__container__items__right {
    align-items: flex-start;
    width: 100%;
  }
  .step4__container__items__right__confirmEmail {
    font-size: 12px;
  }
  .step4__container__items__left {
    width: 100%;
  }

  .stepCard__container__eventName {
    margin-top: 20px;
  }
  .step4__container__button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .step4__container__items__right__table {
    width: 80%;
  }

  .step3__divs__table {
    width: 100%;
  }

  .step6__container__items {
    flex-direction: column-reverse;
  }
  .step6__container__items__right {
    align-items: flex-start;
    width: 100%;
  }
  .step6__container__items__right__confirmEmail {
    font-size: 12px;
  }
  .step6__container__items__left {
    width: 100%;
  }
  .step6__container__button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .step6__container__items__right__table {
    width: 80%;
  }

  .step6__container__header__imgAndText {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 0px;
  }
  .step6__container__header__imgAndText__text__span1 {
    font-size: 17px;
  }

  .stepTable__container {
    width: 100%;
  }
  .step6__container__items {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .step2__container__itemsAndButton {
    margin: 0;
  }

  // .step2__container {
  //   flex-direction: column;
  // }

  .step2__container__img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
  }

  .step2__container__img img {
    // position: absolute;
    // right: 40px;
  }

  .step3__divs {
    width: 100%;
    flex-direction: column;
  }

  .step3__container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;

    // &__divs {
    //   width: 95%;
    // }

    &__upperText {
      span {
        justify-content: flex-start;
      }
    }

    &__minimum {
      justify-content: flex-start;
    }
  }

  .step3__container__button {
    margin-top: 20px;
    justify-content: flex-end;
  }

  .step6__container__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .steperComponent__steperDiv {
    width: 100%;
  }

  .step2__container__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .step1__container__button {
    width: 90%;
  }
}
