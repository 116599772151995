@import "../variables.scss";

.login__body {
  width: 100%;
  height: 100vh;
  background-color: black;
  &__left {
    width: 450px;
    background-image: url("../../../zone-assets/images/zoneLoginImage.png");
    height: 100vh;
    background-repeat: no-repeat;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    box-shadow: 200px -57px 213px 60px rgba(0, 0, 0, 0.83) inset;
    -webkit-box-shadow: 200px -57px 213px 60px rgba(0, 0, 0, 0.83) inset;
    -moz-box-shadow: 200px -57px 213px 60px rgba(0, 0, 0, 0.83) inset;

    &__smallLogo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__img {
      position: absolute;
      bottom: 0px;
      width: 800px;
    }

    &__inputs {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 600px;

      &__form {
        width: 20%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        z-index: 999;
        &__label {
          font-family: $f-regular;
          color: $c-white;
          font-size: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .login_button {
    background-color: #c90200;
    color: white;
    width: 100%;
    height: 40px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    font-size: 16px;
    font-family: $f-regular;
  }
}

.login__body__left__form__inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login__input {
  width: 50%;
  height: 50px;
  min-height: 35px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  /* outline: none; */
  padding-left: 10px;
}

@media (max-width: 600px) {
  .login__body {
    background-image: url("../../../zone-assets/images/zoneLoginImage.png");
    object-fit: cover;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    -webkit-box-shadow: 200px -57px 213px 60px rgba(0, 0, 0, 0.83) inset;
    -moz-box-shadow: 200px -57px 213px 60px rgba(0, 0, 0, 0.83) inset;

    .login__body__left__inputs {
      width: 100%;
      display: flex;
      justify-content: unset;
      height: 540px;
    }
  }
  .login__body__left__inputs__form {
    width: 60%;
  }
  .login__body__left__inputs img {
    width: 200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    height: 25vh;
    gap: 200px;
    position: relative;
    top: 100px;
  }

  .login__body__inputs {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 522px;
  }
}
