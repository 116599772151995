.homepage__components {
  width: 100%;
  height: auto;
  text-transform: capitalize;
  font-family: $f-bold;
  color: white;
  background-color: black;
  margin: 0 auto;
  overflow: hidden;

  &__homeGallery {
    width: 100%;
    // height: auto;

    &__content {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $c-background-black;

      &__container {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        &__span1 {
          text-transform: uppercase;
          font-size: 13px;
        }
        &__span2 {
          text-transform: lowercase;
          width: 40%;
          font-size: 10px;
        }
      }
    }
  }

  &__upcomingEvents {
    width: 100%;
    height: auto;
    padding: 20px;

    &_image {
      border-radius: 20px;
    }

    &__textDiv {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 100px;
      &__text {
        padding: 6px;
        font-family: "Play-Regular";
        font-size: 25px;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 80%;

        @media (max-width: 700px) {
          width: 70%;
        }
      }
    }
  }
}

.zoneData__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage__components__navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  // background: #252424;
  width: 100%;
  height: 70px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inScroll {
  background-color: #000;
  transition: background-color 0.4s ease;
}

.outScroll {
  background-color: unset;
}

.container {
  // width: 1206px;
  width: 100%;
}

.homepage__components__button {
  width: 74px;
  height: 30px;
  border: 1px solid #f20e0f;
  color: white;
  font-size: 10px;
  font-family: "Play-Regular";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: transparent;
  text-transform: uppercase;
  @media (max-width: 500px) {
    width: 90px;
  }
}

@media (min-width: 800px) {
  .homepage__components__navbar {
    display: none;
  }
}

@media (min-width: 700px) {
  .homepage__components__homeGallery__content__container {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }
  .homepage__components__homeGallery__content__container__span1 {
    font-size: 25px;
    font-family: "Play-Regular";
  }
  .homepage__components__homeGallery__content__container__span2 {
    font-size: 20px;
    font-family: "Play-Regular";
  }
}
