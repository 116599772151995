.web__header__container {
  width: 100%;
  overflow: hidden;
  // min-height: 100vh;
  // max-height: calc(100vh - 107px);
  max-height: calc(100vh - 151px);

  &__video {
    width: 100%;
    object-fit: contain;
    // margin-top: 33px;
  }
}
@media (min-width: 700px) {
  .web__header__container {
    // min-height: calc(100vh - 107px);
    min-height: calc(100vh - 151px);
    // object-fit: contain;
    // display: flex;
    // justify-content: center;
  }

  // .web__header__container__video {
  //   margin-top: 0px;
  // }
}
