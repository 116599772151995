.dashboard__container {
  display: flex;

  .container__dashboard__header__left {
    width: 100%;
    height: 100vh;
    height: calc(100vh - 92px);
    overflow-y: auto;

    &__container {
      display: flex;
      flex-direction: column;
      height: 194px;

      &__first {
        display: flex;
        justify-content: space-between;
        width: 100%;
        z-index: 999;
        font-family: $f-bold;
      }
      &__second {
        display: flex;
        justify-content: space-around;
        width: 97%;
        margin-top: 40px;
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }

  .container__dashboard__header__left__first__title {
    margin: 20px 0 0 20px;
  }

  .container__dashboard__header__left__first__date {
    margin: 20px 20px 0 0;
  }

  .dashboard__header_second__first__planed {
    display: flex;
    width: 100%;
  }

  .dashboard__header_second__first {
    width: 20%;
    height: 80px;
    border-right: 1px solid #e2e2e2;
    margin-bottom: 25px;

    &:last-child {
      border-right: none;
    }
  }

  .dashboard__header_second__first__planed__first {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    &__span {
      font-family: $f-regular;
      color: $c-text-nobel;
      font-size: 12px;
    }
  }

  .dashboard__header_second__first__number {
    font-family: $f-bold;
    font-size: 40px;
    display: flex;
    gap: 20px;

    &__arrow {
      margin-top: 20px;
    }
  }

  .container__dashboard__header__right {
    width: 30%;
    height: 145vh;
    border-left: 1px solid #e2e2e2;
  }

  .inspection__container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .cross__charts__bottomBorder {
    border-bottom: 1px solid #e2e2e2;
    width: 80%;

    &:last-child {
      border-bottom: none;
    }
  }

  .cross__charts {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 15px;

    &__inspection {
      font-family: $f-regular;
      color: #3f3c3f;

      &__box {
        margin-top: 20px;
        width: 304px;
        height: 333px;
        max-height: 333px;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
        align-items: center;
        overflow-y: scroll;

        &__data {
          display: flex;
          gap: 5px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          height: 41px;
        }
        &__img {
          width: 40px;
          height: 40px;
          border: 1px solid #ffd338;
          border-radius: 50px;
        }
        &__number {
          margin-top: 10px;
        }

        &__number span {
          font-family: $f-bold;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 27px;
          display: flex;
          align-items: center;
          color: #ffd338;
        }
      }
    }
    &__chart {
      margin-top: 39px;
    }
  }

  .pie__charts {
    font-family: $f-bold;
    font-size: 20px;
    margin: 20px 0 0 40px;
    display: flex;
  }

  .calendar__month {
    font-family: $f-regular;
    color: black;
    padding: 10px;
  }

  .container__dashboard__header__right {
    &__border {
      width: 100%;
      border-bottom: 1px solid #e2e2e2;
    }
    &__second {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;

      &__card {
        width: 100%;
        height: auto;
        min-height: 20px;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        margin-left: 28px;
      }

      &__buttons {
        display: flex;
        margin-left: 28px;
        gap: 20px;

        &__button {
          width: 125px;
          height: 21px;
          background-color: #fffbe9;
          gap: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #ffca0c;
          border: 1px solid #ffca0c;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }

    &__activities {
      font-family: $f-bold;
      padding: 10px;
      height: calc(100vh - 92px);
      overflow-x: auto;
      &__imgdata {
        width: 70%;
        display: flex;
        justify-content: space-between;

        &__img {
          &__image {
            width: 60px;
            height: 60px;
            border: 1px solid #ffd338;
            border-radius: 50px;
            margin-left: 25px;
          }

          &__dashed {
            display: flex;
            flex-direction: column;
            border-left: 5px dotted #e2e2e2;
            height: 100px;
          }
        }

        &__data {
          font-family: $f-regular;

          &__name {
            display: flex;
            gap: 10px;
          }

          &__status {
            border-radius: 5px;
            background-color: #f90000;
            opacity: 50%;
          }

          &__sub {
            color: grey;
          }
        }
      }
    }
  }
}

.activities_box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  &__left {
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &__image {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #ffd338;
      }
    }
    &__line {
      width: 1px;
      height: auto;
      border: 1px dashed #e2e2e2;
      flex: 1;
    }
  }
  &__right {
    &__header {
      display: flex;
      flex-direction: column;
      &__name {
        display: flex;
        justify-content: space-between;
        span {
          font-family: $f-regular;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #1a1a1a;
        }
        &__color {
          font-family: $f-regular;
          font-size: 12px;
          // background: rgba(112, 255, 0, 0.1);
          // border: 1px solid #70ff00;
          border-radius: 5px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          color: black !important;

          // &__danger {
          //   font-family: $f-regular;
          //   font-size: 12px;
          //   background: rgba(237, 71, 0, 0.1);
          //   border: 1px solid #ed4700;
          //   border-radius: 5px;
          //   width: 100%;
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   margin-top: 8px;
          //   color: black !important;
          // }
        }
      }
      &__location {
        font-family: $f-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #939393;
      }
    }
    &__content {
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      padding: 10px;
      margin: 10px 0;
      span {
        font-family: $f-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #939393;
      }
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      &__button {
        background: rgba(255, 211, 56, 0.1);
        border: 1px solid #ffd338;
        border-radius: 5px;
        width: 45%;
        cursor: pointer;
        span {
          font-family: $f-regular;
          font-style: normal;
          font-weight: 800;
          font-size: 10px;
          line-height: 15px;
          text-align: center;
          letter-spacing: -0.3px;
          color: #ffd338;
        }
      }
    }
  }
}

.modalComponent__before {
  font-family: $f-bold;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #ffd338;
}

.modalComponent__products {
  display: flex;
  gap: 2px;
  margin: 20px 0px 20px 0px;

  &__names {
    display: flex;
    width: 127px;
    height: 44px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-family: "Poppins-Bold";
    cursor: pointer;
  }

  &__names:hover {
    width: 127px;
    height: 44px;
    border: 1px solid #ffd338;
    background-color: #ffd338;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $f-bold;
    cursor: pointer;
  }

  &__names.active {
    width: 127px;
    height: 44px;
    border: 1px solid #ffd338;
    background-color: #ffd338;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $f-bold;
    cursor: pointer;
  }
}
