.createEvent__container {
  height: auto;
  margin-top: 20px;
  font-family: $f-bold;

  &__uploader {
    width: 100%;
    border: 1px solid #d9d9d9;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 10px;
  }

  &__nameSurname {
    display: flex;
    gap: 10px;
  }

  &__passwords {
    display: flex;
    gap: 10px;
  }

  &__radioButton {
    font-family: $f-bold;
    font-size: 14px;

    &__aligns {
      display: flex;
      align-items: center;
    }
  }

  &__phoneUserRole {
    display: flex;
    gap: 10px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: flex-end;
    height: 100px;
    font-family: $f-bold;
    margin-top: 30px;
  }

  &__upload {
    margin-top: 10px;
  }

  &__button1 {
    background-color: white;
    width: 132px;
    height: 38px;
    border: 1px solid #3f3c3f;
    font-size: 12px;
    font-family: $f-bold;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  &__allInputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  &__button2 {
    background-color: white;
    width: 132px;
    height: 38px;
    border: 1px solid #d70a05;
    font-size: 12px;
    font-family: $f-bold;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  &__button2:hover {
    background-color: #d70a05;
    width: 132px;
    height: 38px;
    border: 1px solid #d70a05;
    font-size: 12px;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: white;
  }

  .createUser__address {
    width: 510px;
  }
  &-datepicker {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}
.createEvent__container__radioButton__both {
  display: flex;
  justify-content: space-between;
}

.ant-picker-cell-in-view {
  background-color: white !important;
}

.createProduct__header {
  display: flex;
  width: 53%;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 24px;
  font-family: $f-bold;
}
.createEvent__header {
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 24px;
  font-family: $f-bold;
}

//Users is events
