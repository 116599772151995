@import "../variables.scss";

.drawer__container {
  width: 251px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.26);
  font-family: $f-bold;

  &__logo img {
    width: 119px;
    height: 83px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 135px;
  }

  &__settings {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-right: 20px;
  }

  &__settingsLogOut {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    &.active {
      color: #f20e0f;
    }
  }
}

.body-left-box {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  color: $c-text-gray;
  cursor: pointer;
  &:hover {
    .imageNavbar {
      filter: brightness(0) saturate(100%) invert(11%) sepia(95%)
        saturate(5379%) hue-rotate(336deg) brightness(101%) contrast(103%);
    }
  }
}

.body-left-box:hover {
  font-weight: 600;
  color: #f20e0f;
}

.body-left-box.active {
  font-weight: 600;
  color: #f20e0f;
}

.body-left-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.body-left-items.active {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #f20e0f;
}

.border_bottom_container {
  width: 80%;
}

.border_bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}

.hoverBox.active {
  position: absolute;
  width: 10px;
  height: 35px;
  background: #f20e0f;
  left: 241px;
  transition: 0.1s linear all;
}

.svgActive {
  filter: brightness(0) saturate(100%) invert(11%) sepia(95%) saturate(5379%)
    hue-rotate(336deg) brightness(101%) contrast(103%);
}
