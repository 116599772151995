.adminLayout__container {
  width: 100%;
  display: flex;

  &__bottomBorder {
    width: 80%;
    border-bottom: 1px solid lightgray;
  }

  &__navAndChildren {
    width: calc(100% - 251px);
    position: absolute;
    top: 0;
    right: 0;
  }
}

.adminLayout__right {
  width: 100%;
}
.adminLayout__left {
  position: fixed;
  z-index: 100;
  background-color: white;
}

.container {
  position: relative;
  float: left;
  width: 100%;
  height: calc(100vh - 92px);
  overflow-y: auto;
}

.css-80pr5n-MuiPaper-root {
  box-shadow: none !important;
  margin-left: 1px !important;
}

.ant-picker {
  border-radius: 0px;
  border-color: #f20e0f;
  width: 230px;
  padding-left: 15spx;
  cursor: pointer;
  border-radius: 5px;
}

.ant-picker input {
  font-family: "Poppins-Bold" !important;
  font-size: 12px !important;
  cursor: pointer;
}
