@import "fonts.scss";
@import "variables.scss";
@import "margins.scss";
@import "paddings.scss";
@import "appStyle.scss";
@import "./components/button.scss";
@import "./components/drawer.scss";
@import "./components/input.scss";
@import "./components/login.scss";
@import "./components/users.scss";
@import "./components/navbar.scss";
@import "./components/dropdown.scss";
@import "./components/CreateEventInput.scss";
@import "./components/CreateProductInput.scss";
@import "./components/Dashboard.scss";
@import "./components/zone-web/Header.scss";
@import "./components/zone-web/Homepage.scss";
@import "./components/zone-web/EventsCard.scss";
@import "./components/zone-web/Navbar.scss";
@import "./components/zone-web/SliderComponent.scss";
@import "./components/zone-web/EventPage.scss";
@import "./components/zone-web/GalleryPage.scss";
@import "./components/zone-web/Footer.scss";
@import "./components/zone-web/FooterNavbar.scss";
@import "./components/zone-web/Booking.scss";
@import "./components/zone-web/Steps.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

/* Set the width and color of the scrollbar track */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  background-color: #f5f5f5;
}

/* Set the color and radius of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #e70301;
  border-radius: 5px;
}

/* Set the color of the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #fb8684;
}

.ant-modal-content {
  overflow-y: auto !important;
}
