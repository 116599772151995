@import "../variables.scss";

.navbar_button {
  background-color: white;
  width: 132px;
  height: 38px;
  border: 1px solid #f20e0f;
  font-size: 10px;
  font-family: $f-bold;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);

  &__export {
    background-color: white;
    height: 29px;
    border: 2px solid #f20e0f;
    font-size: 10px;
    font-family: $f-bold;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

.navbar_button:hover {
  background-color: #f20e0f;
  color: white;
  svg {
    path {
      stroke: white;
    }
  }
}

.navbar_button_calendar {
  background-color: white;
  width: 175px;
  height: 38px;
  border: 1px solid #ffd338;
  font-size: 10px;
  font-family: $f-bold;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.navbar_button_calendar:hover {
  background-color: #ffd338;
  color: white;
  svg {
    path {
      stroke: white;
    }
  }
}

.eventsCard__button {
  width: 134px;
  height: 35px;
  border: 1px solid #f20e0f;
  color: white;
  font-size: 13px;
  font-family: $f-regular;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: transparent;
  backdrop-filter: blur(1px);
  // background-image: linear-gradient(
  //   to top,
  //   rgba(0.4, 0, 0, 0.7),
  //   rgba(0, 0, 0, 0)
  // );
}
.eventsCard__button:hover {
  width: 134px;
  height: 35px;
  border: 1px solid #f20e0f;
  font-size: 13px;
  font-family: $f-regular;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #f20e0f;
}

@media (min-width: 700px) {
  .eventsCard__button {
    width: 161px;
    height: 37px;
    font-size: 15px;
  }
  .eventsCard__button:hover {
    width: 161px;
    height: 37px;
    font-size: 15px;
  }
}
