@import "../variables.scss";

.delete__modal {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 200px;
  border: 1px solid #ffd338;
  border-radius: 5px;
  z-index: 99999;

  &__items {
    display: flex;
    flex-direction: column;

    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 120px;
      align-items: flex-end;
    }
  }
}
