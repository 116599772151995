@import "../variables.scss";

.navbar__container {
  width: 100%;
  height: 92px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: $f-bold;

  &__content {
    width: 97%;
    height: 92px;
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    &__dropdown {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: auto;
      margin-top: 0px;
      cursor: pointer;
    }
  }

  &__nameAndJobs {
    display: flex;
    flex-direction: column;

    &__name {
      font-family: $f-bold;
      line-height: 15px;
    }

    &__job {
      color: #939393;
    }
  }

  &__left {
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 24px;
    font-weight: 600;

    &__image {
      width: 34px;
      filter: brightness(0) saturate(100%) invert(11%) sepia(95%)
        saturate(5379%) hue-rotate(336deg) brightness(101%) contrast(103%);
    }
  }
}

.navbar_img {
  width: 40px;
  height: 40px;
  border: 2px solid #f20e0f;
  border-radius: 10px;
}

.bottom_border {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.navbar_buttons {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;

  &_navbar_buttons_locationUser {
    display: flex;
  }
}
