.imageGallery__container {
  position: relative;
  width: 100%;
  height: 300px;
  color: white;
  overflow: hidden; /* Ensure the image stays within the container */

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    backdrop-filter: blur(1px);
    // background-image: linear-gradient(
    //   to top,
    //   rgba(0.4, 0, 0, 0.9),
    //   rgba(0, 0, 0, 0)
    // );
  }
}

.eventsCard__container {
  // position: relative;
  width: 60%;
  transform: scale(0.98);
  height: auto;
  color: white;
  overflow: hidden; /* Ensure the image stays within the container */

  @media (max-width: 800px) {
    width: 100%;
    width: 85%;
    margin-left: 22px;
  }

  &__img {
    width: 100%;
    // height: 100%;
    height: 600px; /* height of cards when image upload, if something goes wrong, just delete */
    object-fit: cover;
    border-radius: 20px;
  }

  &__content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    // bottom: 227px;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding: 20px;
    // backdrop-filter: blur(1px);
    // background-image: linear-gradient(
    //   to top,
    //   rgba(0.4, 0, 0, 0.9),
    //   rgba(0, 0, 0, 0)
    // );
    // border-radius: 20px;
    &__button {
      display: flex;
      align-items: flex-end;
    }
    &__nameAndDate {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__name {
        font-size: 22px;
        text-transform: uppercase;
        font-family: $f-bold;
      }
      &__date {
        font-family: $f-regular;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

.eventPage__cardContainer {
  width: 100%;

  @media (max-width: 1600px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-left: unset;
  }
}

.eventsCard__contentClass {
  bottom: 0px;
}

.eventsCard__containerClass {
  // transition: none;
  // transform: none;
}

@media (min-width: 700px) {
  .eventsCard__container__content__nameAndDate__name {
    // font-size: 20px;
  }
  .eventsCard__container__content__nameAndDate__date {
    // font-size: 15px;
  }
  .eventsCard__container {
    transition: all 0.2s ease;
  }
  .eventsCard__container:hover {
    transform: scale(1);
    // width: 100%;
  }
  // .eventsCard__div {
  //   width: 32.4%;
  // }
}

.eventsCard__div {
    width: 33%;
    transform: scale(0.97);
}
@media (max-width: 1200px) {
  .eventsCard__div {
    width: 50%;
  }
}
@media (max-width: 700px) {
  .eventsCard__container__content {
    // bottom: 4px;
    // border-radius: 20px;
    // width: 85%;
    // margin-left: 20px;
    bottom: 4.5px;
    border-radius: 20px;
    width: 94%;
    margin-left: 23px;
    // margin-left: 24px;
  }
  .eventsCard__container__img {
    width: 100%;
    height: 100%;
  }
  .eventPage__img {
    margin-left: 0px;
  }
  .eventsCard__contentClass {
    bottom: 3.5px;
    width: 100.3%;
    margin-left: 0px;
  }
  .eventsCard__div {
    width: 100%;
  }
}