.booking__modal > .ant-modal-content {
  padding: 0;
  z-index: 999999999;
}
.booking__modal {
  width: 80vw;
  height: 95vh;
  overflow-y: hidden;
}
.booking--form {
  width: 100%;
}
.bookingPage__container {
  position: relative;
  background-image: url("../../../../zone-assets//images/bookingBackground.png");
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $f-regular;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.bookingPage__container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px); /* Adjust the blur strength as needed */
  z-index: -1;
}

.bookingPage__modalContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__modal {
    // position: relative;
    background-color: #1c1919;
    box-shadow: 0px 4.14495px 88.0802px rgba(124, 124, 124, 0.25);
    border-radius: 10px 10px 0 0;
    min-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: #fff;

    &__x {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      cursor: pointer;
    }

    &__up {
      width: 100%;
      display: flex;
      height: auto;
      &__headerSteper {
        display: flex;
        flex-direction: column;
        // width: 100%;
        width: 75%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;

        &__text {
          display: flex;
        }
      }

      &__buttonSteper {
        display: flex;
        align-items: center;
        overflow: hidden;
        overflow-x: auto;
        justify-content: flex-start;

        &__render {
          // width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }
      }

      &__img {
        display: flex;
        align-items: center;
      }
      &__spans {
        height: 60px;
        display: flex;
        // justify-content: space-between;
        flex-direction: column;
      }
      &__span1 {
        font-size: 24px;
      }
      &__span2 {
        display: flex;
        font-size: 14px;
        color: #a5a5a5;
        width: 100%;
      }
    }
    &__down {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    }
  }
}

.connection-line {
  background-color: #f20e0f;
  height: 2px;
  flex: auto;
  display: inline-block;
  width: 10px;
}

.steperButtons {
  width: 140px;
  // width: 180px;
  height: 35px;
  // width: 100px;
  // height: 35px;
  border: 1px solid #f20e0f;
  color: white;
  font-size: 16px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: transparent;
}
.steperButtons.active {
  width: 140px;
  height: 35px;
  border: 1px solid #f20e0f;
  font-size: 16px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #f20e0f;
}

.bookingPage__steper__button {
  width: 122px;
  height: 35px;
  border: 1px solid #f20e0f;
  color: white;
  font-size: 14px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: transparent;
}
.bookingPage__steper__button:hover {
  width: 122px;
  height: 35px;
  border: 1px solid #f20e0f;
  font-size: 14px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #f20e0f;
}

@media (max-width: 800px) {
  .booking__modal {
    width: 100vw;
    height: auto;
  }
  .steperButtons {
    width: 140px;
    height: 30px;
    &.active {
      width: 140px;
      height: 30px;
    }
  }

  .steperButtons {
    font-size: 12px;
  }
  .steperButtons.active {
    font-size: 12px;
  }

  .bookingPage__modalContainer__modal {
    overflow: auto;
  }

  .bookingPage__modalContainer__modal__up__img {
    width: 20%;
  }

  .bookingPage__modalContainer__modal__down {
    align-items: unset;
    overflow-y: auto;
  }

  .bookingPage__modalContainer__modal__down__div {
    width: 0%;
  }

  .bookingPage__modalContainer__modal {
    width: 90%;

    // height: 100vh;
  }

  .bookingPage__modalContainer__modal__x {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .bookingPage__modalContainer__modal__up__headerSteper {
    width: 100%;
  }

  .bookingPage__modalContainer__modal__up {
    align-items: flex-end;
    width: 100%;
  }

  .bookingPage__container__sidebar {
    height: 100px;
  }

  .bookingPage__modalContainer__modal__up__img {
    // height: 100px;
    img {
      width: 64px;
      // left: 20px;
      // top: 54px;
    }
  }
  .bookingPage__modalContainer__modal {
    width: 90%;
  }

  .bookingPage__modalContainer__modal__up__headerSteper__text {
    width: 100%;
    height: 65px;
    align-items: center;
    gap: 10px;
  }

  .bookingPage__modalContainer__modal__up__spans {
    align-items: flex-end;
    justify-content: center;
  }

  .bookingPage__modalContainer__modal__up__span1 {
    font-size: 20px;
    width: 100%;
  }

  .bookingPage__modalContainer__modal__up__span2 {
    font-size: 13px;
    width: 100%;
    display: flex;
    // justify-content: flex-end;
  }
  .bookingPage__modalContainer__modal__up__buttonSteper {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    justify-content: flex-start;
  }
  .bookingPage__modalContainer__modal__up__buttonSteper::-webkit-scrollbar {
    background-color: transparent;
  }
  .bookingPage__modalContainer__modal__up__buttonSteper::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set the scrollbar thumb color */
  }
}

@media (min-width: 800px) {
  .bookingPage__modalContainer__modal__up__headerSteper__text {
    // width: 29%;
    // display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .bookingPage__modalContainer__modal__up__headerSteper__div {
    width: 100%;
  }
  .bookingPage__modalContainer__modal__up__buttonSteper {
    width: 100%;
  }
}

@media (min-width: 600px) {
  .bookingPage__modalContainer__modal__up__buttonSteper {
    width: 100%;
  }
  .bookingPage__modalContainer__modal__up__buttonSteper__render {
    width: 100%;
  }
  .bookingPage__modalContainer__modal {
    height: calc(100vh - 70px);
  }
  .bookingPage__modalContainer__modal__up__img img {
    width: 70px;
  }
  .booking--form {
    width: 90%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .bookingPage__modalContainer__modal__up__span1 {
    font-size: 17px;
  }
  .bookingPage__modalContainer__modal__up__span2 {
    font-size: 12px;
  }
}

@media (max-width: 390px) {
  .bookingPage__modalContainer__modal__up__span1 {
    font-size: 18px;
  }
  .bookingPage__modalContainer__modal__up__span2 {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .steperButtons {
    width: 100px;
  }
  .steperButtons.active {
    width: 100px;
  }
}
