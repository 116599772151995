.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
}

.ant-picker-cell-in-view {
  background: #ffd338 !important;
}

/* .ant-modal {
  border: none;
  outline: none;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 588px 378.2px;
} */
/* .ant-modal-center1111 {
  border: none;
  outline: none;
  display: block;
  position: absolute;
  top: 28% !important;
  left: 35% !important;
  transform: none !important;
  transform-origin: unset !important;
} */
.ant-select-selection-item {
  line-height: 23px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#swiper-slider-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 200px;
  /* margin-right: 5px; */
}

/* height of cards when image upload, if something goes wrong, just delete */
#swiper-slider-cards .swiper-slide {
  height: 600px !important;
}

@media (max-width: 800px) {
  #swiper-slider-cards {
    margin-left: unset;
  }
}

#swiper-slider-cards .swiper {
  width: 300px;
  height: 380px;
}

#swiper-slider-cards .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

#swiper-slider-default .swiper {
  width: 100%;
  /* height: 500px; */
}

#swiper-slider-default .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  /* width: 300px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
}

#swiper-slider-default .swiper-slide img {
  display: block;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.swiper-3d {
  perspective: 584px !important;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

.swiper-3d .swiper-slide-shadow {
  background: none !important;
}

@media (min-width: 600px) {
  #swiper-slider-cards .swiper {
    width: 800px;
    height: 500px;
  }

  /* #swiper-slider-default .swiper {
    width: 60%;
    height: 500px;
  } */
}

@media (max-width: 600px) {
  #swiper-slider-cards .swiper {
    width: 400px;
    height: 534px;
  }
}

.pswp-docs__home-gallery {
  grid-gap: 10px;
  display: grid;
  /* grid-template-columns: 1fr 0.5fr 0.5fr; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  position: relative;
  width: 100%;
}
.pswp-docs__home-gallery .pswp-docs__home-gallery-item {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}
.pswp-docs__home-gallery .pswp-docs__home-gallery-item a {
  display: block;
  height: 100%;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.pswp-docs__home-gallery .pswp-docs__home-gallery-item img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.pswp__item img {
  object-fit: contain;
  transition: 0.3s linear all;
  /* width: 300px !important; */
  /* height: 300px !important; */
}
/* .pswp__img, .pswp__zoom-wrap {
  width: 100% !important;
  height: 100% !important;
} */

.masonry-layout {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.masonry-item {
  flex: 1 0 200px;
  margin: 10px;
}

.masonry-item img {
  max-width: 100%;
  height: auto;
}
