@import "../variables.scss";

.input_component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  // min-height: 72px;
  label {
    width: 100%;
    font-family: $f-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    // color: #fff;
    color: black;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 3px;
  }
  input {
    width: 100%;
    height: 35px;
    min-height: 35px;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    outline: none;
    padding-left: 10px;
    &::placeholder {
      font-family: $f-regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      // color: #c4c4c4;
      color: #000;
    }
  }
  &__error {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: red;
    margin-left: 3px;
  }

  .createEvent__name {
    color: black;
    width: 100%;
  }

  .createEvent__poster {
    width: 100%;
    height: 154px;
  }

  .createUser__password {
    color: black;
    width: 400px;
  }
  .createStatus__name {
    width: 50%;
    height: 40px;
  }
  .createStatus__color {
    width: 50%;
    height: 40px;
    padding-left: 2px;
    padding: 7px;
  }
}

.input__password {
  position: relative;
}

.eye__icon {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  right: 10px;
  padding-right: 1px;
  cursor: pointer;
}

.radioButton__container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.uploadPhoto__container {
  width: 100%;
  &__image {
    // width: 100%;
    height: auto;
    border-radius: 5px;
  }
  &__image img {
    width: 498px;
    height: 203px;
    object-fit: cover;
    border-radius: 10px;
    object-position: center;
  }
}

#file__upload {
  display: none;
}

.upload__file {
  background-color: white;
  width: 132px;
  height: 38px;
  border: 1px solid #f20e0f;
  font-size: 12px;
  font-family: $f-bold;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.upload__file:hover {
  background-color: #f20e0f;
  width: 132px;
  height: 38px;
  border: 1px solid #f20e0f;
  font-size: 12px;
  font-family: $f-bold;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  color: white;
  border-radius: 10px;
}

.eventPosterName {
  width: 100%;
  font-family: $f-regular;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  // color: #fff;
  color: black;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 3px;
}
