@import '../../fonts.scss';

$f-regular: "Poppins-Regular";
$f-bold: "Poppins-Bold";
$button-hover-color: #bd1f24;

.contact-form-container {
  width: 100%;
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form-content {
  width: 95%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form-header {
  text-align: start;
  margin-bottom: 20px;
  font-family: $f-regular;
  font-size: 32px;
  @media (max-width:450px) {
    text-align: center;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 10px;
  }
}

.form-col1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-items: start;

  @media (max-width: 450px) {
    width: 100%;
    align-items: center;
  }
}
  
.form-col2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-items: end;

  @media (max-width: 450px) {
    width: 100%;
    align-items: center;
  }
}

.form-input {
  padding: 15px;
  padding-left: 20px;
  border: 1px solid #E8272B;
  font-family: $f-regular;
  background-color: transparent;
  color: white;
  font-size: 16px;
  width: 530px;
  height: 55px;
  margin-bottom: 10px;
  
  &::placeholder {
    color: #FFFFFF;
  }

  &:focus {
    border-color: $button-hover-color;
    outline: none;
  }

  @media (max-width: 450px) {
    width: calc(100% - 40px);
  }
}

.form-textarea {
  width: 670px;
  height: 205px;
  padding: 15px;
  padding-left: 20px;
  color: white;
  border: 1px solid #E8272B;
  background-color: transparent;
  font-family: $f-regular;
  font-size: 16px;
  min-height: 100px;
  resize: none;
  margin-bottom: 10px;
  
  &::placeholder {
    color: #FFFFFF;
  }

  &:focus {
    border-color: $button-hover-color;
    outline: none;
  }

  @media (max-width: 450px) {
    width: calc(100% - 40px);
  }
}

.form-button {
  width: 195px;
  height: 55px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #E8272B;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: $f-regular;

  &:hover {
    background-color: $button-hover-color;
  }

  @media (max-width: 450px) {
    width: calc(100% - 40px);
  }
}
