.createProduct__container {
  gap: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 579px;
  margin-top: 20px;
  font-family: $f-bold;

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__nameSurname {
    display: flex;
    gap: 10px;
  }

  &__email {
    display: flex;
    gap: 10px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: flex-end;
  }

  &__address {
    display: flex;

    &__address {
      width: 94%;
    }
  }

  &__button1 {
    background-color: white;
    width: 132px;
    height: 38px;
    border: 1px solid #3f3c3f;
    font-size: 12px;
    font-family: $f-bold;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  &__button2 {
    background-color: white;
    width: 132px;
    height: 38px;
    border: 1px solid #d70a05;
    font-size: 12px;
    font-family: $f-bold;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  &__button2:hover {
    background-color: #d70a05;
    width: 132px;
    height: 38px;
    border: 1px solid #d70a05;
    font-size: 12px;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: white;
  }

  .createUser__address {
    width: 510px;
  }

  .createLocation__label__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
  }

  .createLocation__label {
    color: #ffd338;
    font-family: $f-bold;
    font-size: 12px;
  }
  .custom-price-input {
    width: 160px
  }
}
.createLocation__header {
  display: flex;
  width: 39%;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  font-family: $f-bold;
}

//Location is product
