.galleryPage__container {
  background: #000;
  color: white;
}

.galleryPage__container__homeGallery__content {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-background-black;

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__span1 {
      text-transform: uppercase;
      font-size: 13px;
    }
    &__span2 {
      text-transform: lowercase;
      width: 40%;
      font-size: 10px;
    }
  }
}

.galleryPage__sidebar {
  height: 100px;
}

.navbarDesktop__gallery {
  // height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 999;
  background-color: #000;

  // background-color: red;
}
@media (min-width: 700px) {
  .navbarDesktop__gallery {
    padding: 10px;
    z-index: 999;
  }

  .galleryPage__container__homeGallery__content__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }
  .galleryPage__container__homeGallery__content__container__span1 {
    font-size: 24px;
    font-family: "Play-Regular";
  }
  .galleryPage__container__homeGallery__content__container__span2 {
    font-size: 20px;
    font-family: "Play-Regular";
  }
  .zoneData__container {
    margin-top: 100px;
  }
}

@media (max-width: 800px) {
  .navbarDesktop__gallery {
    display: none;
  }
}
