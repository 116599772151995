.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease-out;
  z-index: 999;
  transform: translateX(-100%);
}

.navbar.open {
  transform: translateX(0);
  overflow: hidden;
}

.navbar.closed {
  transform: translateX(-100%);
}

.button-toggle {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.navbar__content {
  color: red;

  &__ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 25px;
    cursor: pointer;
  }
}

.navbar__icon {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

//Navbar Desktop

.navbarDesktop__container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  font-family: $f-regular;
  text-transform: uppercase;
  // background-color: blue;
  align-items: flex-end;
  // background-color: red;

  &__image {
    cursor: pointer;
    img {
      width: 130px;
      margin-bottom: 20px;
    }
  }

  &__listAndButton {
    display: flex;
    width: 50%;
    justify-content: space-around;
    margin-bottom: 20px;
  }
}

.navbarDesktop__list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  & .active {
    position: relative;
  }
  & .active::after {
    position: absolute;
    content: "";
    bottom: -4px;
    left: 1px;
    width: 100%;
    height: 2px;
    // border: 1px solid #000;
    background: linear-gradient(90deg, #d70a05 0%, rgba(215, 10, 5, 0) 94.06%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

.navbarDesktop__button {
  display: flex;
  width: 50%;
  justify-content: space-around;
}


.navbarDesktop__button1 {
  width: 122px;
  height: 35px;
  border: 1px solid #f20e0f;
  color: white;
  font-size: 14px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: transparent;
}
.navbarDesktop__button1:hover {
  width: 122px;
  height: 35px;
  border: 1px solid #f20e0f;
  font-size: 14px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #f20e0f;
}

@media (max-width: 800px) {
  .navbarDesktop__container {
    display: none;
  }
}
