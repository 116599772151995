.eventPage__container {
  width: 100%;
  height: auto;
  background-color: #000;
  color: white;
  text-transform: capitalize;

  &__navigationBar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 25px;

    &__text {
      font-size: 12px;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 50px;
    }

    &__input {
      width: 75%;
      display: flex;
      justify-content: flex-end;

      &__container {
        width: 200px;
        height: 30px;
        border: 1px solid #f20e0f;
        color: white;
        font-size: 10px;
        font-family: "Play-Bold";
        align-items: center;
        display: flex;
        justify-content: center;
        gap: 10px;
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        background-color: transparent;
      }

      &__placeholder {
        padding-inline: 10px;
        background-color: #000;
        width: 200px;
        color: white;
      }
    }
  }

  &__events {
    display: flex;
    flex-direction: column;

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
  }
}

.navbarDesktop__eventsPage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 999;
}

.eventPage__sideBar {
  height: 100px;
}

.eventsPage__button1 {
  width: 60px;
  height: 30px;
  border: 1px solid #f20e0f;
  color: white;
  font-size: 10px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: transparent;

  &.active {
    width: 60px;
    height: 30px;
    border: 1px solid #f20e0f;
    font-size: 10px;
    font-family: "Play-Bold";
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    background-color: #f20e0f;
  }
}

.eventsPage__button1:hover {
  width: 60px;
  height: 30px;
  border: 1px solid #f20e0f;
  font-size: 10px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #f20e0f;
  transition: color, 0.3s;
}

.eventsPage__button2 {
  width: 100px;
  height: 30px;
  border: 1px solid #f20e0f;
  color: white;
  font-size: 10px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: transparent;

  &.active {
    width: 100px;
    height: 30px;
    border: 1px solid #f20e0f;
    font-size: 10px;
    font-family: "Play-Bold";
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    background-color: #f20e0f;
  }
}

.eventsPage__button2:hover {
  width: 100px;
  height: 30px;
  border: 1px solid #f20e0f;
  font-size: 10px;
  font-family: "Play-Bold";
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #f20e0f;
}

.custom-rangepicker {
  &-wrapper {
    width: 200px;
    height: 20px;
  }
  & .ant-picker {
    height: 30px;
    background-color: transparent;
    border: 1px solid #f20e0f;
    border-radius: 20px;

    &-input {
      input {
        color: #bdc0cc;
      }
    }
  }
}

@media (min-width: 700px) {
  .textAndButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .eventPage__container__events {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  .allButtons {
    display: flex;
  }

  .eventPage__container__navigationBar {
    width: 35%;
  }

  .eventPage__container__navigationBar__input {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .custom-rangepicker {
    &-wrapper {
      width: 200px;
      height: 42px;
    }
    & .ant-picker {
      height: 42px;
    }
  }

  .eventPage__container__navigationBar__input__container {
    width: 200px;
    height: 42px;
    font-size: 24px;
  }

  .eventsPage__button1 {
    width: 62px;
    font-size: 14px;
    height: 42px;

    &.active {
      width: 62px;
      font-size: 14px;
      height: 42px;
    }
  }

  .eventsPage__button1:hover {
    width: 62px;
    font-size: 14px;
    height: 42px;
  }

  .eventsPage__button2 {
    width: 118px;
    height: 42px;
    font-size: 14px;

    &.active {
      width: 118px;
      height: 42px;
      font-size: 14px;
    }
  }

  .eventsPage__button2:hover {
    width: 118px;
    height: 42px;
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .eventPage__container__navigationBar__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
.event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 200px;
  @media (max-width: 500px) {
    margin-bottom: 0;
  }
}

.event-header {
  border: 2px solid gray;
  width: 400px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 50px;
  font-family: "Play-Regular";
  font-weight: normal;
  @media (max-width: 500px) {
    width: 350px;
    margin-bottom: 25px;
  }
}

.event-title {
  font-size: 34px;
  font-family: "Play-Regular";
  font-weight: normal;

  @media (max-width: 500px) {
    font-size: 24px;
  }
}

.event-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 10px;
  width: 100%;
  justify-content: center;
  padding: 0 50px;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
}

.event-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  max-width: 400px;
  height: 500px;
  margin: 0 auto;

  @media (max-width: 500px) {
    margin-bottom: 10px;
    width: 90%;
    height: 500px;
  }
}

.event-image-container {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    height: 100%;
    margin-bottom: 10px;
  }
}

.event-image-wrapper {
  height: 100%;
}

.event-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.no-image {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.event-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  height: 40px;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.event-button {
  background: none;
  border: 1px solid red;
  color: inherit;
  cursor: pointer;
  outline: inherit;
  width: 48%; 
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  text-transform: uppercase;
}
.event-button:hover{
  background-color: #f20e0f;
}
.event-button-text{
  font-weight: normal;
  font-family: "Play-Regular";
  font-size: 14px;
}