@import "../variables.scss";

.dropdown_component {
  display: flex;
  flex-direction: column;
  // width: 100%;
  height: auto;
  margin-top: 10px;
  cursor: pointer;
  &::placeholder {
    color: #000;
  }
  label {
    width: 100%;
    font-family: $f-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    // color: #fff;
    color: black;
    margin-bottom: 10px;
    margin-left: 3px;
  }
  .ant-select-selector {
    width: 100% !important;
    height: 35px !important;
    background-color: #ffffff !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 5px !important;
    outline: none !important;
    padding-left: 10px !important;
    &::placeholder {
      font-family: $f-regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      // color: #c4c4c4;
      color: #000;
    }
  }

  .ant-select-single {
    // width: 250px;
  }
  .ant-select-selection-item {
    line-height: 40px !important;
  }
  input {
    height: 35px !important;
  }
  &__error {
    color: red;
    margin-left: 3px;
  }
}

.createLocation__dropDownCity {
  width: 100%;
}

.navbar__dropdown {
  width: 99%;
  margin: 0;
  gap: 3px;
  display: flex;
  flex-direction: column;
}
