.footerNavbar__container {
  &__items {
  }

  &__img {
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 40px;

    @media (max-width: 450px) {
      &__imgAndList {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
    }
  }

  &__ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    color: white;
    text-align: center;
    cursor: pointer;

    @media (max-width: 450px) {
      flex-direction: row;
      gap: 15px;
    }

    &__links {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: red;
      }
    }
  }

  &__socialNav {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;

    @media (max-width: 450px) {
      flex-direction: column;
      gap: 20px;
      margin-top: 0;
    }
  }

  &__social__desktop {
    display: flex;
    justify-content: center;
    gap: 40px;

    @media (max-width: 450px) {
      gap: 10px;
      img {
        width: 35px;
      }
    }
  }

  &__allRights {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      gap: 5px;
      text-align: center;
      margin-bottom: 250px;

    }

    &__text {
      font-size: 11px;
      color: white;

      @media (max-width: 450px) {
        font-size: 12px;
        text-align: center;
      }
    }

    &__paysera {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 450px) {
        img {
          width: 50px; // Adjust the size for smaller screens
        }
      }
    }

    &__social__desktop {
      display: flex;
      gap: 20px;

      @media (max-width: 450px) {
        gap: 10px;
      }
    }
  }

  &__social__borderContainer {
    width: 100%;
    display: flex;
    justify-content: center;

    &__bottomBorder {
      width: 90%;
      border-bottom: 1px solid #747474;
      margin-top: 40px;

      @media (max-width: 450px) {
        display: none;
      }
    }
  }
}

.footerNavbar__container__social__desktop {
  display: none;

  @media (max-width: 450px) {
    display: flex;
  }
}

  .footerNavbar__container__list {
    flex-direction: row;
  }


@media (min-width: 700px) {
  .footerNavbar__container__ul {
    flex-direction: row;
    font-family: $f-regular;
  }

  .footerNavbar__container__socialNav {
    flex-direction: row;
  }

  .footerNavbar__container__social {
    display: none;
  }

  .footerNavbar__container__list__imgAndList {
    width: 91%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footerNavbar__container__img {
    width: 20%;
  }
}

  .footerNavbar__container__allRights__bottomBorder {
    position: flex;
    margin: auto;
    width: 88%;
    border-bottom: 1px solid #747474;
    @media (max-width:450px) {
      width: 0;
      display: none;
    }
  }

  .footerNavbar__container__allRights {
    gap: 30px;
    margin-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 90px;

    @media (max-width:450px) {
      flex-direction: column-reverse;
      padding-inline: 20px;
      margin-top: 0;
      gap: 10px;
    }
  }

  .footerNavbar__container__allRights__text {
    font-size: 14px;
    font-family: $f-regular;
    font-weight: 600;
  }


  .footerNavbar__container__social__borderContainer {
    display: none;
  }

  .footerNavbar__container__social__desktop {
    display: flex;
    gap: 20px;
    @media (max-width:450px) {
      flex-direction: row;
      gap: 10px;
    }
  }

